import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
import home from '../views/home/index.vue'
import aboutlucida from '../views/aboutLucida/index.vue'
import strategy from '../views/strategy/index.vue'
import moving from '../views/moving/index.vue'

/*配置路由*/
const routes = [
    { path: "/", component: home, name: 'home' },
    { path: "/aboutlucida", component: aboutlucida, name: 'aboutlucida' },
    { path: "/strategy", component: strategy, name: 'strategy' },
    { path: "/home", component: moving, name: 'moving' },
    {     path: '*',
       redirect: '/', 
       hidden: true 
  }
]
/*实例化路由*/
const router = new VueRouter({
    mode: 'history',
    routes // （简写）相当于 routes: routes
})
// router.beforeEach((to, from, next) => {
//     // console.log("to", to)
//     // console.log("from", from.name)
//     if (from.name !== null) {

//         // next({path:'/'}); //所有前置守卫最后都需要调用next()进入下一个管道
//         next();
//     } else {
//         next({ path: "/" });
//     }

// })

//导航后置守卫，可以在确定导航到目标页面时再更改title
// router.afterEach((to, from) => {
//     window.document.title = to.name;
// })

/*导出路由模块*/
export default router