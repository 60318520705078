<template>
  <div id="content-boss" style="width: 100%; height: 100%; position: relative">
    <div class="box" ref="zoom">
      <header-nav></header-nav>
      <div class="aboutLucidaBoss">
        <div class="LUCIDA-text color-white">
          LUCIDA is an industry-leading quantitative hedge fund with its main
          office in the Cayman Islands. Officially entered the cryptocurrency
          Industry in April 2018, with
          <span style="color: yellow">excellent risk control ability</span>
          and
          <span style="color: yellow">stable profitability</span>
          , passed through the bull-bear market several times,
          <span style="color: yellow"
            >and repeatedly won awards in industry competitions.</span
          >
        </div>
        <div class="LUCIDA-text LUCIDA-text-two">
          At present, the team manages 30 million dollars, including 14 million
          dollars of proprietary assets.
        </div>
      </div>
      <div class="center-boss">
        <!-- 白点 -->
        <div class="center-radius-white center-radius-white1"></div>
        <div class="center-radius-white center-radius-white2"></div>
        <div class="center-radius-white center-radius-white3"></div>
        <div class="center-radius-white center-radius-white4"></div>
        <div class="center-radius-white center-radius-white5"></div>
        <div class="center-radius-white center-radius-white6"></div>
        <div class="center-radius-white center-radius-white7"></div>
        <div class="center-radius-white center-radius-white8"></div>
        <div class="center-radius-white center-radius-white9"></div>
        <div class="center-radius-white center-radius-white10"></div>
        <div class="center-radius-white center-radius-white11"></div>
        <div class="center-radius-white center-radius-white12"></div>
        <!-- 文字 -->
        <div class="text-1 text textFalimy">
          <div calss="date">2018.4</div>
          <div class="Access">Access to</div>
          <div>Crypto Market</div>
        </div>
        <div class="text-2 text textFalimy">
          <div calss="date">2018.7</div>
          <div class="Access">Profit Exceeding</div>
          <div>$1.5million</div>
        </div>
        <div class="text-3 text textFalimy">
          <div calss="date">2019.8</div>
          <div class="Access">Bgain Digital Asset Traing League</div>
          <div class="color-bottom">Mixed Strategy Group Champion</div>
        </div>
        <div class="text-4 text textFalimy">
          <div calss="date">2020.3</div>
          <div class="Access">In"3.12" <span class="color-bottom">Black Swan Incidents</span></div>
          <div class="color-bottom">Sell At Top</div>
        </div>
        <div class="text-5 text textFalimy">
          <div calss="date">2020.5</div>
          <div class="Access">Tokenlnsight Global Asset </div>
          <div>Quantification Competition</div>
          <div class="color-bottom">The Therd Place in The Season</div>
        </div>
        <div class="text-6 text textFalimy">
          <div calss="date">2020.11</div>
          <div class="Access">Management Scale Exceeding </div>
          <div class="color-bottom">$ 8 million</div>
        </div>
        <div class="text-7 text textFalimy">
          <div calss="date">2021.3</div>
          <div class="Access">Management Scale Exceeding</div>
          <div>Exceeding</div>
          <div>$ 16 million</div>
        </div>
        <div class="text-8 text textFalimy">
          <div calss="date">2021.5</div>
          <div class="Access">In "5.19"<span class="color-bottom">Black Swan Incidents</span> </div>
          <div class="color-bottom">Make Lots of Profit</div>
        </div>
        <div class="text-9 text textFalimy">
          <div calss="date">2021.9</div>
          <div class="Access">TokenInsight x KuCoin  </div>
          <div>Global Asset</div>
          <div>Quantification Competition</div>
          <div class="color-bottom"> the composite Strategy Group </div>
          <div class="color-bottom">season third place</div>
        </div>
        <div class="text-10 text textFalimy">
          <div calss="date">2021.11</div>
          <div class="Access">Sold All Of Tokens</div>
          <div>For An Average Of $ 55,000 Each</div>
        </div>
        <div class="text-11 text textFalimy">
          <div calss="date">2022.5</div> 
          <div class="Access">Management Scale</div>
          <div>Exceeding</div>
          <div>$ 30 million</div>
        </div>
        <div class="text-12 text textFalimy">
          <div calss="date">2022.11</div>
          <div class="Access color-bottom"> Falcon,</div>
          <div>New Generation</div>
          <div>Web3.0 Infrastructure,</div>
          <div>Was Released.</div>
        </div>
      </div>
      <div class="home-bottom">
          <a class="a" href="https://beian.miit.gov.cn" target="_blank"
            >京ICP备2022036119号</a
          >
        </div>
    </div>
  </div>
</template>

<script>
import drawMixin from "../../assets/js/utils/drawMixin";
import headerNav from "../../../src/components/header/index.vue";

export default {
  //注册mixin
  mixins: [drawMixin],
  data() {
    return {};
  },
  components: {
    headerNav,
  },
};
</script>

<style lang="less" scoped>
.textFalimy{
  font-family: "Outfit-Light";
}
.date{
  font-family: "Outfit-Medium";
}
.aboutLucidaBoss {
  width: 100%;
  height: 100%;
  background: url("../../assets/image/bg/1@/about2.png");
  background-size: 100% 100%;
  font-family: "Outfit-Regular";
  .LUCIDA-text {
    position: absolute;
    top: 231px;
    left: 60px;
    width: 1066px;
    height: 101px;
    color: #ffffff;
    font-family: Outfit-Light;
    line-height: 28px;
    font-size: 18px;
  }
  .LUCIDA-text-two {
    margin-top: 85px;
  }
}

.center-boss {
  position: absolute;
  bottom: 20%;
  width: 100%;
  height: 40%;
  .center-radius-white {
    width: 20px;
    height: 20px;
    // background: #ffffff;
    background: url('../../assets/image/sta/yuan-4.png');
    background-size: 100% 100%;
    border-radius: 50%;
  }
  .center-radius-white1 {
    position: absolute;
    top: 342px;
    left: 102px;
  }

  .center-radius-white2 {
    position: absolute;
    top: 176px;
    left: 167px;
  }

  .center-radius-white3 {
    position: absolute;
    top: 288px;
    left: 283px;
  }
  .center-radius-white4 {
    position: absolute;
    top: 196px;
    left: 413px;
  }
  .center-radius-white5 {
    position: absolute;
    top: 223px;
    left: 567px;
  }
  .center-radius-white6 {
    position: absolute;
    top: 156px;
    left: 710px;
  }

  .center-radius-white7 {
    position: absolute;
    top: 182px;
    left: 854px;
  }

  .center-radius-white8 {
    position: absolute;
    top: 128px;
    left: 1015px;
  }

  .center-radius-white9 {
    position: absolute;
    top: 173px;
    left: 1159px;
  }
  .center-radius-white10 {
    position: absolute;
    top: 108px;
    left: 1316px;
  }
  .center-radius-white11 {
    position: absolute;
    top: 182px;
    left: 1469px;
  }
  .center-radius-white12 {
    position: absolute;
    top: 144px;
    left: 1644px;
  }
  .text {
    color: white;
    font-family: "Outfit-Light";
    font-size: 14px;
  }
  .text-1 {
    position: absolute;
    top: 420px;
    left: 108px;
  }
  .text-2 {
    position: absolute;
    top: 90px;
    left: 174px;
  }
  .text-3 {
    position: absolute;
    top: 368px;
    left: 290px;
  }
  .text-4 {
    position: absolute;
    top: 112px;
    left: 420px;
  }
  .text-5 {
    position: absolute;
    top: 300px;
    left: 572px;
  }
  .text-6 {
    position: absolute;
    top: 72px;
    left: 716px;
  }

  .text-7 {
    position: absolute;
    top: 264px;
    left: 858px;
  }

  .text-8 {
    position: absolute;
    top: 40px;
    left: 1020px;
  }

  .text-9 {
    position: absolute;
    top: 249px;
    left: 1166px;
  }
  .text-10 {
    position: absolute;
    top: 20px;
    left: 1320px;
  }
  .text-11 {
    position: absolute;
    top: 258px;
    left: 1476px;
  }
  .text-12 {
    position: absolute;
    top: 12px;
    left: 1648px;
  }
}

.Access {
  margin-top: 10px;
}

.color-bottom{
  color:#FEEF46;
}
.home-bottom {
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 61px;
  font-family: "Outfit-Light";
  /* font-size: 20px; */
  // text-align: center;
}
.a {
  color: white;
}
</style>