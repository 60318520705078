<template >
  <div class="headerBoss">
    <div class="logo-white">
      <div class="logo-image-boss">
        <img
          src="../../assets/image/home_slices/logoWhite.png"
          class="logo-image"
        />
      </div>
    </div>
    <div class="header-tabbar">
      <div class="header-tabbar-title"></div>
      <div
        class="header-tabbar-title"
        :class="homeShow ? 'header-tabbar-title2' : ''"
        @click="rotuerAction('Home')"
      >
        Home
      </div>
      <div
        class="header-tabbar-title"
        :class="aboutShow ? 'header-tabbar-title2' : ''"
        @click="rotuerAction('About Lucida')"
      >
        About Lucida
      </div>
      <div
        class="header-tabbar-title"
        :class="staShow ? 'header-tabbar-title2' : ''"
        @click="rotuerAction('Strategy')"
      >
        Strategy
      </div>
      <div class="header-tabbar-title special" @click="rotuerAction('Falcon')">
        Falcon<img class="fill" src="../../assets/image/home_slices/fill.png" />
      </div>
      <div class="header-tabbar-title"></div>
    </div>
    <div class="right-chosee">
      <img src="../../assets/image/home_slices/bird.png" @click="toBird"/>
      <div class="email" @click="showLink">
        <img src="../../assets/image/home_slices/emall.png" />
      </div>
      <div v-if="show" class="link">
        <span class="text">zhengnaiqian@lucida.fund</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      homeShow: false,
      aboutShow: false,
      staShow: false,
      faiconShow: false,
      show: false,
    };
  },
  mounted() {
    var url = this.$route.path;
    console.log("this.$router.path", this.$route.path);
    if (url == "/") {
      this.homeShow = true;
      this.staShow = false;
      this.aboutShow = false;
      this.faiconShow = false;
    } else if (url == "/strategy") {
      this.homeShow = false;
      this.staShow = true;
      this.aboutShow = false;
      this.faiconShow = false;
    } else if (url == "/aboutlucida") {
      this.aboutShow = true;
      this.staShow = false;
      this.homeShow = false;
      this.faiconShow = false;
    }
  },
  methods: {
    rotuerAction(data) {
      if (data == "Home") {
        this.$router.push("/");
      } else if (data == "About Lucida") {
        this.$router.push("/aboutlucida");
      } else if (data == "Strategy") {
        this.$router.push("/strategy");
      } else if (data == "Falcon") {
        // this.$router.push("/");
        window.open('http://falcon.lucida.fund/', '_blank')
      }
    },
    showLink() {
      this.show = !this.show;
    },
    toBird(){
      window.open('https://twitter.com/FalconLucida', '_blank')
    }
  },
};
</script>

<style lang="less" scoped>
.special {
  color: #ffffff;
}
.email {
  display: inline-block;
  position: relative;
}
.link {
  width: 169px;
  height: 54px;
  background: url("../../assets/image/sta/polygon.png");
  position: absolute;
  top: 53px;
  right: 39px;
  font-size: 12px;
  color:#ffffff;
  font-family: "Outfit-Regular";
  line-height: 54px;
  
  .text{
    padding-left: 15px;
    // cursor: pointer;
    text-decoration: underline;
  }
  //   .bg {

  //   }
  // .text {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }
}
.headerBoss {
  height: 50px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  padding-top: 5px;
  .logo-white {
    height: 100%;
    width: 10%;
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    .logo-image-boss {
      height: 50%;
      img {
        width: 114px;
        height: 25px;
        margin-left: 20px;
        margin-right: 20px;
        padding-right: 20px;
        border-right: 1px #333338 solid;
      }
    }
  }
  .header-tabbar {
    color: #9d9d9d;
    display: inline-flex;
    width: 85%;
    height: 100%;
    font-family: "Outfit-Regular";
    .header-tabbar-title {
      width: 16.6%;
      text-align: center;
      // padding-bottom: 15px;
      line-height: 50px;
      .fill {
        position: relative;
        top: 8px;
        margin-left: 10px;
      }
    }
    .header-tabbar-title:hover {
      cursor: pointer;
    }
    .header-tabbar-title2 {
      background: url("../../assets/image/home_slices/headerTabbar.png");
      background-size: 100% 100%;
      font-family: "Outfit-Bold";
      color: #ffffff;
    }
  }

  .right-chosee {
    width: 7%;
    img {
      margin-left: 5px;
      cursor: pointer;
    }
  }
}
</style>