<template>
  <div id="app">
    <!-- <header-nav class="header-boss"></header-nav> -->
    <router-view></router-view>
  </div>
</template>

<script>
// import headerNav from "../src/components/header/index.vue";

export default {
  name: "App",
  components: {
    // headerNav,
  },
  data() {
    return {};
  },
  mounted() {
    console.log("window.location", window.location);
    window.addEventListener(
      "hashchange",
      () => {
        // console.log("window.location.hash", window.location.hash);
        var currentPath = window.location.hash.slice(1); // 获取输入的路由

        // if (this.$router.path !== currentPath) {
        //   this.$router.push(currentPath); // 动态跳转
        // }
      },
      false
    );
  },
};
</script>

<style>
html,
body,
#app {
  height: 100%;
  width: 100%;
}
#app {
  background-color: black;
}
</style>
