<template>
  <div id="content-boss" style="width: 100%; height: 100%; position: relative">
    <div class="box" ref="zoom">
      <header-nav></header-nav>
      <div class="home-boss">
        <div class="home-image"></div>
        <div class="home-ridous"></div>
        <div class="home-text">
          Industry leading crypto asset quantification hedge fund
        </div>
        <div class="home-bottom">
          <a class="a" href="https://beian.miit.gov.cn" target="_blank"
            >京ICP备2022036119号</a
          >
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import drawMixin from "../../assets/js/utils/drawMixin";
import headerNav from "../../../src/components/header/index.vue";

export default {
  mixins: [drawMixin],
  data() {
    return {};
  },
  components: {
    headerNav,
  },
};
</script>


<style scoped>
.home-boss {
  width: 100%;
  height: 100%;
  background: url("../../assets/image/home_slices/homeBg.png");
  background-size: 100% 100%;
}

.home-image {
  background: url("../../assets/image/home_slices/asset.png");
  background-size: 100% 100%;
  width: 876px;
  height: 252px;
  position: absolute;
  top: 341px;
  left: 47px;
}

.home-ridous {
  background: url("../../assets/image/home_slices/zhuangshi.png");
  background-size: 100% 100%;
  width: 94px;
  height: 18px;
  position: absolute;
  top: 649px;
  left: 65px;
}

.home-text {
  color: white;
  position: absolute;
  top: 716px;
  left: 61px;
  font-family: "Outfit-Light";
  font-size: 20px;
}
.home-bottom {
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 61px;
  font-family: "Outfit-Light";
  /* font-size: 20px; */
  /* text-align: center; */
}
.a {
  color: white;
}
</style>