<template>
  <div class="Mobile" ref="bgc">
    <el-scrollbar :height="height" @scroll="scroll" ref="scroll">
      <el-main>
        <img src="../../assets/image/sta/movingBG.png" alt="" />
        <div class="Falcon" @click="toFill">
          <img
            class="fill"
            src="../../assets/image/home_slices/Paper-fill.png"
          />
        </div>
        <div class="right-chosee" ref="ri">
          <img src="../../assets/image/home_slices/bird.png" @click="toBird" />
          <div class="email" @click="showLink">
            <img src="../../assets/image/home_slices/emall.png" />
          </div>
          <div v-if="show" class="link">
            <span class="text">zhengnaiqian@lucida.fund</span>
          </div>
        </div>
        <div class="home-bottom">
          <a class="a" href="https://beian.miit.gov.cn" target="_blank"
            >京ICP备2022036119号</a
          >
        </div>
      </el-main>
    </el-scrollbar>
  </div>
</template>
<script>
import drawMixin from "../../assets/js/utils/drawMixin";

export default {
  mixins: [drawMixin],
  data() {
    return {
      show: false,
      height: document.body.clientHeight,
      scrollTop: 0,
      clientHeight:0,
      riHeight:0,
      top:0,
    };
  },
  watch: {
    scrollTop(value) {
      // document.getElementsByClassName("right-chosee")[0].scrollTop = 300+value
      // this.$refs.ri.scrollTop = 300+value
      // this.$refs.ri.style.position = 'absolute'
      // console.log(document.body.clientHeight)
      // console.log(this.$refs.ri.offsetHeight-35-85)
      // this.$refs.ri.style.top = document.body.clientHeight-this.$refs.ri.offsetHeight-22 + value + "px";
      // console.log("页面高度", this.$refs.ri.scrollTop);
    },
  },
  mounted() {
    this.clientHeight = document.body.clientHeight
    this.riHeight = this.$refs.ri.offsetHeight
    this.top = this.clientHeight-this.riHeight-52
    // document.getElementsByClassName("right-chosee")[0].scrollTop = 300
    window.addEventListener("scroll", this.handleScroll);
    // document.body.onscroll = function () {
    //   this.scrollTop =
    //     document.documentElement.scrollTop || document.body.scrollTop;
    //   console.log(this.scrollTop);
    // };

    // console.log("页面高度",  this.$refs.ri.offsetTop);
    // this.getScrollTop()
  },
  methods: {
    toFill() {
      window.open("http://falcon.lucida.fund/", "_blank");
    },
    showLink() {
      this.show = !this.show;
    },
    toBird() {
      window.open("https://twitter.com/FalconLucida", "_blank");
    },
    handleScroll() {
      //或者使用document.querySelector('.class或者#id').scrollTop
      this.scrollTop =
        document.documentElement.scrollTop || document.body.scrollTop;
        this.$refs.ri.style.top = this.top + this.scrollTop + "px";
      // console.log(this.scrollTop);
    },
    // getScrollTop() {
    //   if (document.documentElement && document.documentElement.scrollTop) {
    //     this.scrollTop = document.documentElement.scrollTop;
    //   } else if (document.body) {
    //     this.scrollTop = document.body.scrollTop;
    //   }
    //   console.log('滚动高度',this.scrollTop)
    // },
  },
};
</script>
<style lang="less" scoped>
.Mobile {
  /* background-image: url("../../assets/image/sta/移动端邮箱弹窗.png"); */
  /* background-size: 100% 100%; */
  width: 100%;
  background-color: #000000;
  /* height: 100%; */
  /* background-repeat: repeat-x; */
  /* background-attachment:scroll; */
}
.el-main {
  padding: 0;
}
img {
  width: 100%;
}
.Falcon {
  position: absolute;
  top: 20px;
  right: -10px;
  cursor: pointer;
  img {
    width: 70%;
  }
}
.right-chosee {
  position: fixed;
  top: 825px;
  right: 30px;
  img{
    width: 40px;
  }
  .email{
    margin-top: 10px;
  }
}
.link {
  width: 319px;
  height: 54px;
  background: url("../../assets/image/home_slices/polygon2.png");
  background-repeat: no-repeat;
  background-size: 320px 54px;
  transform: scale(0.7,0.7);
  position: absolute;
  top: 47px;
  right: 0px;
  font-size: 22px;
  color: #ffffff;
  font-family: "Outfit-Regular";
  line-height: 54px;

  .text {
    display: inline-block;
    width: 295px;
    padding-left: 15px;
    // cursor: pointer;
    text-decoration: underline;
    background: url("../../assets/image/home_slices/复制文件.png");
    background-position: right;
    background-repeat: no-repeat;
  }
  //   .bg {

  //   }
  // .text {
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  // }
}
.home-bottom {
  color: white;
  width: 100%;
  position: absolute;
  bottom: 2px;
  left: 0;
  font-family: "Outfit-Light";
  /* font-size: 20px; */
  text-align: center;
}
.a {
  color: white;
}
</style>