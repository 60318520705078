<template>
  <div id="content-boss" style="width: 100%; height: 100%; position: relative">
    <div class="box" ref="zoom">
      <header-nav></header-nav>
      <div class="sta-boss">
        <!-- 上面部分单独的文字 -->
        <div class="sta-img">
          <div>
            <span>LUCIDA captures</span>
            <span class="color-yellow"></span>
            <span>medium and long-term trend following opportunit</span>
            <span> and</span>
            <span class="color-yellow"> arbitrage opportunities </span>
          </div>
          <div>
            <span class="color-yellow">
              and the Regression of Implied Volatility opportunities</span
            >
            <span> based on the</span>
            <span class="color-yellow">Multi-Factor Model.</span>
          </div>
        </div>
        <div class="left-quan">
          <div class="Option title">
            <div>Option</div>
            <div>Strategy</div>
          </div>
          <div class="Profit title">
            <div>Profit</div>
            <div>Composition</div>
            <div class="point"></div>
          </div>
          <div class="Dynamic title">
            <div>Dynamic</div>
            <div>Delta Hedging</div>
            <div class="point"></div>
          </div>
          <div class="Source title">
            <div>Source of Profit</div>
            <div class="point"></div>
          </div>
        </div>
        <div class="center-quan">
          <div class="Multi-Factor title">
            <div>Multi-Factor</div>
            <div>Model</div>
          </div>
          <div class="Alternative title">
            <div>Alternative Data</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="Event title">
            <div>Event</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="Technical title">
            <div>Technical Indicator</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="Price title">
            <div>Price & Volume</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="Fundamental title">
            <div>Fundamental</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="Derivatives title">
            <div>Derivatives</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="On-Chain title">
            <div>On-Chain</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
        </div>
        <div class="right-top-quan">
          <div class="Trend title">
            <div>Trend</div>
            <div>Following</div>
            <div>Strategy</div>
          </div>
          <div class="Medium title">
            <div>Low to Medium</div>
            <div>Trading Frequency</div>
            <div class="point"></div>
          </div>
          <div class="Leverage title">
            <div>Low Leverage</div>
            <div>Low Drawdown</div>
            <div class="point"></div>
          </div>
          <div class="High title">
            <div>High Odds</div>
            <div class="point"></div>
          </div>
          <div class="Market title">
            <div>Market Timing</div>
            <div>Strategy</div>
            <div class="point"></div>
          </div>
          <div class="Fundamental title">
            <div>Fundamental</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="Derivatives title">
            <div>Derivatives</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
          <div class="On-Chain title">
            <div>On-Chain</div>
            <div>Factors</div>
            <div class="point"></div>
          </div>
        </div>
        <div class="right-bottom-quan">
          <div class="Statistical title">
            <div>Statistical</div>
            <div>Arbitrage</div>
            <div>Strategy</div>
          </div>
          <div class="Sharpe title">
            <div>High Sharpe Ratio</div>
            <div class="point"></div>
          </div>
          <div class="Minimum title">
            <div>Minimum Drawdown</div>
            <div class="point"></div>
          </div>
          <div class="Compound title">
            <div>Compound Strategy</div>
            <div class="point"></div>
          </div>
        </div>
        <div class="Scattered">
          <div class="left-Scattered">
            <div class="Bull">Bull - Bear Market Spread</div>
            <div class="Short-term">Short-term Gamma Scalping profit</div>
            <div class="Premium">Premium</div>
            <div class="Calendar">Calendar Spread</div>
            <div class="Gamma">Gamma Exposure</div>
            <div class="Vega">Vega Exposure</div>
          </div>
          <div class="center-Scattered">
            <div class="Communit title">
              <div>Chat Record of</div>
              <div>Communit</div>
            </div>
            <div class="Search title">
              <div>Search Times</div>
              <div>on Google</div>
            </div>
            <div class="Public title">
              <div>Public Opinion</div>
              <div>Monitoring</div>
            </div>
            <div class="Exponenti title">
              <div>Exponenti al</div>
              <div>Moving Average</div>
            </div>
            <div class="Relative title">
              <div>Relative Strength</div>
              <div>Index</div>
            </div>
            <div class="Coding title">
              <div>Coding</div>
              <div>On Github</div>
            </div>
            <div class="Cap title">
              <div>Market</div>
              <div>Cap</div>
            </div>
            <div class="Difficulty title">
              <div>Mining</div>
              <div>Difficulty</div>
            </div>
            <div class="Mechanism title">
              <div>Consensus</div>
              <div>Mechanism</div>
            </div>
            <div class="Circulating title">
              <div>Circulating/Max/Total</div>
              <div>Supply</div>
            </div>
            <div class="Total title">
              <div>Total</div>
              <div>Addresses</div>
            </div>
            <div class="Concentration title">
              <div>Chip</div>
              <div>Concentration</div>
            </div>
            <div class="Balances title">
              <div>Address</div>
              <div>Balances</div>
            </div>
            <div class="Moving">Moving Average</div>
            <div class="Volume">Volume on DEX</div>
            <div class="Price2">Price</div>
            <div class="CEX">Volume on CEX</div>
            <div class="Contract">Contract Basis</div>
            <div class="Implied">Implied Volatility</div>
            <div class="Position">Position Size</div>
            <div class="Funding">Funding Rates</div>
          </div>
          <div class="right-Scattered">
            <div class="Rate">Rate Arbitrage</div>
            <div class="Cash">Cash Futures Arbitrage</div>
            <div class="Statistics">Basis Statistics Arbitrage</div>
          </div>
        </div>
      </div>
      <div class="home-bottom">
          <a class="a" href="https://beian.miit.gov.cn" target="_blank"
            >京ICP备2022036119号</a
          >
        </div>
    </div>
    <!-- <div v-show="isMobie" class="Mobile" ref="bgc">
      <img src="../../assets/image/sta/移动端邮箱弹窗.png" alt="">
    </div> -->
  </div>
</template>

<script>
import drawMixin from "../../assets/js/utils/drawMixin";
import headerNav from "../../../src/components/header/index.vue";

export default {
  mixins: [drawMixin],
  components: {
    headerNav,
  },

  data() {
    return {};
  },
};
</script>

<style  scoped>
.sta-boss {
  background: url("../../assets/image/sta/sta-bg.png");
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
.right-bottom-quan {
  font-family: "Outfit-Regular";
}
.right-top-quan {
  font-family: "Outfit-Regular";
}
.center-quan {
  font-family: "Outfit-Regular";
}
.left-quan {
  font-family: "Outfit-Regular";
}

.sta-img {
  position: absolute;
  top: 125px;
  left: 38px;
  color: white;
  font-size: 20px;
  /* font-weight: 500; */
  font-family: "Outfit-Medium";
}

.color-yellow {
  color: yellow;
  /* text-shadow: 0 0 0.1em yellow, 0 0 0.1em yellow; */
}

.Option {
  position: absolute;
  top: 572px;
  left: 245px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0 0 0.1em #fff, 0 0 0.1em #fff;
  font-family: "Outfit-Bold";
}
.Multi-Factor {
  position: absolute;
  top: 514px;
  left: 910px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0 0 0.1em #fff, 0 0 0.1em #fff;
  font-family: "Outfit-Bold";
}
.Trend {
  position: absolute;
  top: 400px;
  left: 1534px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0 0 0.1em #fff, 0 0 0.1em #fff;
  font-family: "Outfit-Bold";
}
.Statistical {
  position: absolute;
  top: 680px;
  left: 1640px;
  color: white;
  font-size: 20px;
  font-weight: 500;
  text-shadow: 0 0 0.1em #fff, 0 0 0.1em #fff;
  font-family: "Outfit-Bold";
}
.title {
  text-align: center;
}
.point {
  margin: 10px auto;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #fff;
  font-size: 12;
  box-shadow: 0 0 13px #fff;
}
.Profit {
  position: absolute;
  top: 490px;
  left: 352px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Dynamic {
  position: absolute;
  top: 600px;
  left: 356px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Source {
  position: absolute;
  top: 684px;
  left: 282px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Alternative {
  position: absolute;
  top: 376px;
  left: 900px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Event {
  position: absolute;
  top: 400px;
  left: 1030px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Technical {
  position: absolute;
  top: 490px;
  left: 1056px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Price {
  position: absolute;
  top: 590px;
  left: 1024px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Fundamental {
  position: absolute;
  top: 624px;
  left: 882px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Derivatives {
  position: absolute;
  top: 530px;
  left: 796px;
  display: grid;
  color: white;
  font-size: 12px;
}
.On-Chain {
  position: absolute;
  top: 440px;
  left: 816px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Medium {
  position: absolute;
  top: 272px;
  left: 1466px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Leverage {
  position: absolute;
  top: 350px;
  left: 1390px;
  display: grid;
  color: white;
  font-size: 12px;
}
.High {
  position: absolute;
  top: 450px;
  left: 1404px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Market {
  position: absolute;
  top: 504px;
  left: 1460px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Sharpe {
  position: absolute;
  top: 578px;
  left: 1548px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Minimum {
  position: absolute;
  top: 686px;
  left: 1484px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Compound {
  position: absolute;
  top: 776px;
  left: 1534px;
  display: grid;
  color: white;
  font-size: 12px;
}
.Scattered {
  color: #999999;
  font-size: 10px;
  font-family: "Outfit-Regular";
}
.Bull {
  position: absolute;
  top: 450px;
  left: 410px;
}
.Short-term {
  position: absolute;
  top: 476px;
  left: 450px;
}
.Premium {
  position: absolute;
  top: 502px;
  left: 466px;
}
.Calendar {
  position: absolute;
  top: 528px;
  left: 460px;
}
.Gamma {
  position: absolute;
  top: 708px;
  left: 356px;
}
.Vega {
  position: absolute;
  top: 734px;
  left: 346px;
}
.Communit {
  position: absolute;
  top: 324px;
  left: 866px;
}
.Search {
  position: absolute;
  top: 336px;
  left: 968px;
}
.Public {
  position: absolute;
  top: 410px;
  left: 1078px;
}
.Exponenti {
  position: absolute;
  top: 488px;
  left: 1162px;
}
.Relative {
  position: absolute;
  top: 532px;
  left: 1130px;
}
.Coding {
  position: absolute;
  top: 684px;
  left: 940px;
}
.Cap {
  position: absolute;
  top: 676px;
  left: 880px;
}
.Difficulty {
  position: absolute;
  top: 636px;
  left: 804px;
}
.Mechanism {
  position: absolute;
  top: 720px;
  left: 860px;
}
.Circulating {
  position: absolute;
  top: 690px;
  left: 728px;
}
.Total {
  position: absolute;
  top: 470px;
  left: 736px;
}
.Concentration {
  position: absolute;
  top: 424px;
  left: 712px;
}
.Balances {
  position: absolute;
  top: 392px;
  left: 778px;
}
.Moving {
  position: absolute;
  top: 580px;
  left: 1120px;
}
.Volume {
  position: absolute;
  top: 612px;
  left: 1104px;
}
.Price2 {
  position: absolute;
  top: 638px;
  left: 1090px;
}
.CEX {
  position: absolute;
  top: 664px;
  left: 1042px;
}
.Contract {
  position: absolute;
  top: 588px;
  left: 720px;
}
.Implied {
  position: absolute;
  top: 566px;
  left: 676px;
}
.Position {
  position: absolute;
  top: 544px;
  left: 712px;
}
.Funding {
  position: absolute;
  top: 520px;
  left: 700px;
}
.Rate {
  position: absolute;
  top: 784px;
  left: 1420px;
}
.Cash {
  position: absolute;
  top: 814px;
  left: 1438px;
}
.Statistics {
  position: absolute;
  top: 844px;
  left: 1456px;
}
.home-bottom {
  color: white;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 61px;
  font-family: "Outfit-Light";
  /* font-size: 20px; */
  /* text-align: center; */
}
.a {
  color: white;
}
</style>